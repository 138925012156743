import React, {useEffect, useRef, useState} from 'react';
import { Link } from 'react-router-dom';
// import loading from '../assets/images/loading.svg'
import styles from '../styles/Home.module.css';
import {useTranslation} from "react-i18next";
import {Helmet} from 'react-helmet-async';
import {Dialog} from '@headlessui/react'
import {Bars3Icon, XMarkIcon} from '@heroicons/react/24/outline'
import SEO from "./SEO";

import HomeNewsEntrance from './HomeNewsEntrance';
import BrainCog_Born from './BrainCog_Born';
import Datasets from './Datasets';
import {Fade} from "react-awesome-reveal";
import heroBackgroundImage from '../assets/images/hero_background/home_hero_7.jpg';

function Home() {
    const {t, i18n} = useTranslation(['home', 'articles', 'research', 'html_title', 'common']);
    const isChinese = i18n.language === 'zh';
    const newsList = [t(`news`, {ns: 'articles', returnObjects: true})[0]];console.log(newsList);
    const researchEntries = t('research_entries', {ns: 'research', returnObjects: true});


    const services = [
        {name: "mind_talk", href: 'http://mind-engine.org/mind-talk/', intro: t("service_intro_mind_talk")},
        {name: "mind_mate", href: 'http://mind-engine.org/mind-mate/', intro: t("service_intro_mind_mate")},
        {name: "symbiosis_panorama", href: 'http://symbiosis-panorama.ai-for-sdgs.academy/cn/', intro: t("service_intro_mind_talk")},
        {name: "cie", href: 'http://cultural-interactions-engine.ai-for-sdgs.academy/', intro: t("service_intro_mind_talk")},
        {name: "digital_philosophers", href: 'http://cn.digital-philosophers.org/', intro: t("service_intro_mind_talk")},
        {name: "sea", href: 'https://safe-and-ethical.ai/', intro: t("service_intro_mind_talk")},
    ];

    const organizations_cn = [
        {name: t("organization_name_1"), href: 'javascript:void(0)', intro: t("organization_intro_1")},
        {name: t("organization_name_2"), href: 'javascript:void(0)', intro: t("organization_intro_2")},
        {name: t("organization_name_3"), href: 'javascript:void(0)', intro: t("organization_intro_3")},
        {name: t("organization_name_4"), href: 'https://long-term-ai.center/', intro: t("organization_intro_4")},
    ];

    const organizations_en = [
        {name: t("organization_name_4"), href: 'https://long-term-ai.center/', intro: t("organization_intro_4")},
        {name: t("organization_name_1"), href: 'javascript:void(0)', intro: t("organization_intro_1")},
        {name: t("organization_name_2"), href: 'javascript:void(0)', intro: t("organization_intro_2")},
        {name: t("organization_name_3"), href: 'javascript:void(0)', intro: t("organization_intro_3")},
    ];

    return (
        <div className="bg-white pb-64">
            <SEO title={t('home', {ns: 'html_title'})}></SEO>
            {/*hero*/}
            <div
                className={`relative isolate px-6 pt-14 lg:px-8 sm:min-h-[900px] bg-animation ${styles.bg_animation} flex items-center justify-center`}
                style={{backgroundImage: `url(${heroBackgroundImage})`}}
            >
                <div className="absolute inset-0 -z-1"></div>
                <div className="mx-auto max-w-7xl py-24 sm:py-48 lg:py-52 z-0">
                    {/*                    <div className="hidden sm:mb-16 sm:flex sm:justify-center">
                        <div
                            className="relative rounded-full px-6 py-1 text-sm leading-6 text-gray-300 ring-1 ring-gray-100/50 hover:ring-gray-100/40"
                        >
                            {t("announcing")}.{'  '}
                            <a href="#services" className="font-semibold text-indigo-400">
                                <span className="absolute inset-0" aria-hidden="true"/>
                                {t("get_started")} <span aria-hidden="true">&rarr;</span>
                            </a>
                        </div>
                    </div>*/}
                    <div className="text-center">
                        <div
                            className={`font-bold tracking-[.1em] bg-clip-text text-transparent drop-shadow-2xl bg-gradient-to-b from-[#c00000] to-[#c00000] ${isChinese ? ' sm:text-[3.5rem] lg:text-[3.5rem] leading-tight sm:tracking-[.10em]' : 'sm:text-[3.5rem] leading-normal sm:leading-tight sm:tracking-[.01em]'}`}>
                            {t('hero_heading_1')}
                        </div>
                        <p className={`mt-12 text-lg font-light font-sans text-neutral-700 sm:text-neutral-700 sm:text-xl leading-tight sm:leading-8 ${isChinese ? 'tracking-[.05em] sm:tracking-[.01em]' : 'text-left leading-5 tracking-[.01em] sm:tracking-[.01em]'}`}>
                            {t('hero_heading_2')}
                        </p>
                        <div className="mt-12 sm:mt-[7rem] flex items-center justify-center gap-x-6">
                            {/*                            <a
                                href="#engines"
                                className="rounded-3xl bg-transparent border-2 border-neutral-400 px-9 pt-2.5 pb-2 text-sm font-normal text-neutral-300 shadow-sm hover:bg-neutral-200/10 duration-150 tracking-[.2em] uppercase"
                            >
                                {t("get_started")}
                            </a>*/}
                            <a href="#logo_animation" id="action_button" className="relative inline-flex h-12 overflow-hidden rounded-full p-[2px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50">
                                <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#ffcccc_0%,#c00000_50%,#ffcccc_100%)]"></span>
                                <span
                                    className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-white px-9 pt-2.5 pb-2 backdrop-blur-3xl text-sm font-normal text-neutral-800 shadow-sm hover:bg-[#c00000]/30 hover:text-white duration-150 tracking-[.2em] uppercase">
                                {t("get_started")}
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div id={"logo_animation"} className={"flex justify-center items-center flex-col pt-12 px-0 bg-white"}>
                <video
                    src={process.env.PUBLIC_URL + `/logo/red_logo_min.mp4`}
                    className={"max-w-[375px] bg-white rounded-full p-[2px]"}
                    loop
                    muted
                    autoPlay
                />
            </div>
            <div id={"news"} className={"flex justify-center items-center flex-col pt-16 pb-16 px-5 bg-white"}>
                {newsList && newsList.map((item, i) => (
                    <HomeNewsEntrance key={i} teaser_title={item.teaser_title} teaser_content={item.teaser_content} teaser_url={item.teaser_url} teaser_src={item.teaser_src} date={item.date}/>
                ))}
            </div>
            {/*研究成果*/}
            <div className={"flex justify-center items-center flex-col pt-24 pb-16 px-5 bg-white"}>
                <div className={"w-[100%] sm:w-[75%] xl:w-[75%] max-w-7xl mx-auto"}>
                    <a className={`block text-4xl md:text-5xl bg-clip-text text-transparent bg-[#c00000] sm:bg-gradient-to-b from-[#c00000] to-[#ffe6e6] font-extrabold text-center leading-relaxed md:leading-loose ${isChinese ? 'tracking-[.03em]' : 'tracking-[.02em]'}`}
                       href="/research-highlights"
                    >
                        {isChinese ? '研究成果' : 'Research Highlights'} &#11166;
                    </a>
                </div>
                <div className={""}>
                    <div className={"gap-5 lg:max-w-[90%] 2xl:max-w-[80%] flex flex-wrap justify-center items-start max-w-8xl mx-auto px-6 sm:px-12 gap-y-6 rounded-3xl bg-cover bg-white bg-blend-multiply bg-center-top bg-no-repeat"}>
                        {researchEntries && researchEntries.slice(0, 6).map((entry, index) => (
                            <div className="mt-24 gap-5 max-w-sm mx-auto flex flex-wrap flex-col justify-content-center align-items-start" href={entry.teaser_url}>
                                <div className={"flex justify-content-start align-items-center"}>
                                    <img src={process.env.PUBLIC_URL + `/images${entry.teaser_url}/${entry.teaser_src}`} className={"h-auto max-h-[200px] rounded-lg object-contain"} alt={"news-image"}/>
                                </div>
                                <div className="sm:max-w-[400px] lg:max-w-[600px] xl:max-w-[800px]">
                                    <h2 className={"text-neutral-700 text-xl font-semibold mt-4 mb-4"}>{entry.teaser_title}</h2>
                                    <div className={"text-neutral-700 text-lg font-light font-sans"}>{entry.teaser_content}</div>
                                    {/*<a href={teaser_url} className={"block mt-5 sm:mt-5 lg:mt-6 text-neutral-700 text-lg font-light uppercase"}>*/}
                                    <Link to={entry.content_url} className={"group block mt-5 sm:mt-5 lg:mt-6 text-neutral-600 text-lg font-sans font-medium uppercase"}>
                                        <span className="underline decoration-transparent group-hover:decoration-red-600 underline-offset-8 decoration-2 transition-colors duration-500">{t("learn_more_button", {ns:"research"})}</span>
                                        <span className="pl-2 group-hover:pl-3.5 transition-all duration-300 text-red-600 font-extrabold text-2xl">&rsaquo; </span>
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <hr />
                <Link to={"/research-highlights"} className={"justify-self-end align-right group block mt-10 sm:mt-10 lg:mt-14 text-neutral-600 text-lg  font-sans font-medium uppercase text-center leading-loose"}>
                    <span className="underline decoration-gray-300 group-hover:decoration-red-600 underline-offset-8 decoration-2 transition-colors duration-500">{t("show_all_button", {ns:"research"})}</span>
                    {/*<span className="pl-2 group-hover:pl-3.5 transition-all duration-300 text-red-600 font-extrabold text-2xl">&rsaquo; </span>*/}
                </Link>
            </div>
            <div id={"assessment_system"} className={"flex justify-center items-center flex-col pt-12 px-5 bg-white"}>
                <div className={"w-[100%] sm:w-[75%] xl:w-[75%] max-w-7xl mx-auto"}>
                    <a className={`block text-4xl md:text-5xl bg-clip-text text-transparent bg-[#c00000] sm:bg-gradient-to-b from-[#c00000] to-[#ffe6e6] font-extrabold text-center leading-relaxed md:leading-loose ${isChinese ? 'tracking-[.03em]' : 'tracking-[.02em]'}`}
                       href="/ai-ethics-and-safety-assessment-system"
                    >
                        {isChinese ? '人工智能伦理与安全评测体系' : 'AI Ethics and Safety Assessment System'} &#11166;
                    </a>
                </div>
            </div>
            <BrainCog_Born brainknow={true}/>
            <div className={"flex justify-center items-center flex-col pt-24 px-5 bg-white"}>
                <div className={"w-[100%] sm:w-[75%] xl:w-[75%] max-w-7xl mx-auto"}>
                    <a className={`block text-4xl md:text-5xl bg-clip-text text-transparent bg-[#c00000] sm:bg-gradient-to-b from-[#c00000] to-[#ffe6e6] font-extrabold text-center leading-relaxed md:leading-loose ${isChinese ? 'tracking-[.03em]' : 'tracking-[.02em]'}`}
                       href="/safe-ai-foundational-model"
                    >
                        {isChinese ? '安全人工智能基础模型' : 'Safe AI Foundational Model'} &#11166;
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Home