import {useParams, useLocation, Link} from "react-router-dom";
import '../styles/Common.css';
import '../styles/About.css';
import {useTranslation, Trans} from "react-i18next";
import {Helmet} from 'react-helmet-async';
import SEO from "./SEO";
import heroBackgroundImage from '../assets/images/hero_background/about_hero_bg_original4.jpg';
import React from "react";


const ResearchHighlights = () => {
    const {t, i18n} = useTranslation(['research', 'common', 'title', 'home'])
    const isChinese = i18n.language === 'zh';

    // Fetch all news articles
    const entries = t('research_entries', {ns: 'research', returnObjects: true});
    // console.log("entries:", entries);


    return (
        <div className={"bg-white overflow: auto"}>
            <SEO title={t('research_highlights', {ns: 'html_title'})}></SEO>
            <div className={"bg-cover bg-white bg-blend-hard-light bg-bottom bg-no-repeat min-h-[350px] sm:min-h-[400px] w-full flex flex-col justify-start items-center p-6"}
                 style={{backgroundImage: `url(${heroBackgroundImage})`}}
            >
                <div className={"flex flex-col justify-center items-start max-w-[900px] mt-36 mb-16 md:mr-48"}>
                    <div className={` font-bold tracking-[.1em] text-white/90 leading-normal ${isChinese ? 'text-4xl sm:text-[3.5rem] sm:tracking-[.02em]' : 'text-4xl sm:text-[3rem] sm:tracking-[.02em]'}`}>
                        {t('section_title')}
                    </div>
                </div>
            </div>
            <div className={"content-container py-12"}>
                <div className={"gap-5 lg:max-w-[90%] 2xl:max-w-[80%] flex flex-wrap justify-center items-start max-w-8xl mx-auto px-6 sm:px-12 pb-36 rounded-3xl bg-cover bg-white bg-blend-multiply bg-center-top bg-no-repeat"}>
                    {entries && entries.map((entry, index) => (
                        <div className="mt-24 gap-5 max-w-sm mx-auto flex flex-wrap flex-col justify-content-center align-items-start" href={entry.teaser_url}>
                            <div className={"flex justify-content-start align-items-center"}>
                                <img src={process.env.PUBLIC_URL + `/images${entry.teaser_url}/${entry.teaser_src}`} className={"h-auto max-h-[200px] rounded-lg object-contain"} alt={"news-image"}/>
                            </div>
                            <div className="sm:max-w-[400px] lg:max-w-[600px] xl:max-w-[800px]">
                                <h2 className={"text-neutral-700 text-xl font-semibold mt-4 mb-4"}>{entry.teaser_title}</h2>
                                <div className={"text-neutral-700 text-lg font-light font-sans"}>{entry.teaser_content}</div>
                                {/*<a href={teaser_url} className={"block mt-5 sm:mt-5 lg:mt-6 text-neutral-700 text-lg font-light uppercase"}>*/}
                                <Link to={entry.content_url} className={"group block mt-5 sm:mt-5 lg:mt-6 text-neutral-600 text-lg font-sans font-medium uppercase"}>
                                    <span className="underline decoration-transparent group-hover:decoration-red-600 underline-offset-8 decoration-2 transition-colors duration-500">{t("learn_more_button", {ns:"research"})}</span>
                                    <span className="pl-2 group-hover:pl-3.5 transition-all duration-300 text-red-600 font-extrabold text-2xl">&rsaquo; </span>
                                </Link>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ResearchHighlights;
